<template>
  <GenericReadonlyComponent
    v-if="computedReadOnly"
    :value="readOnlyValue"
    :label="label"
    :labelCols="labelCols"
  ></GenericReadonlyComponent>
  <UnvalidatedSelectComponent
    v-else-if="rules == null"
    :options="options"
    :label="label"
    :labelCols="labelCols"
    :disabled="disabled"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  ></UnvalidatedSelectComponent>
  <ValidatedSelectComponent
    v-else
    :options="options"
    :label="label"
    :labelCols="labelCols"
    :disabled="disabled"
    :rules="rules"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  ></ValidatedSelectComponent>
</template>

<script>
import GenericReadonlyComponent from "./GenericReadonlyComponent.vue";
import UnvalidatedSelectComponent from "./UnvalidatedSelectComponent.vue";
import ValidatedSelectComponent from "./ValidatedSelectComponent.vue";
export default {
  name: "SelectDeboucheComponent",
  components: {
    GenericReadonlyComponent,
    UnvalidatedSelectComponent,
    ValidatedSelectComponent,
  },
  props: {
    // valeur du select (type sans importance)
    value: undefined,
    // propriété label du form-group
    label: {
      type: String,
      default: "Débouché",
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // true pour affichage lecture seule (false par défaut)
    readOnly: [Boolean, String],
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // règles de validation à appliquer (aucune par défaut)
    rules: [String, Object],
  },
  watch: {
    value: "load",
  },
  data: () => ({
    options: [],
  }),
  computed: {
    computedReadOnly() {
      return this.readOnly === true || this.readOnly === "true";
    },
    readOnlyValue() {
      return this.$store.getters["ref/debouche"](this.value)?.libelle;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      // Récupérer les types de culture
      let options = this.$store.state.ref.debouches;

      // Les transformer en options
      options = [
        { value: null, text: "" },
        ...options.map((t) => ({
          value: t.code,
          text: t.libelle,
        })),
      ];

      this.options = options;
    },
  },
};
</script>
