<template>
  <section>
    <TitleBar tabsParent="recolte">
      Campagne {{ $route.params.codeRecolte }}
      <template #toolbar-content>
        <b-button-group v-if="semisEnMasseAutorise">
          <b-button pill variant="primary" @click="semisEnMasse()">
            <b-icon-check-all></b-icon-check-all>
            J'ai sem&eacute; tout...
          </b-button>
        </b-button-group>
        &nbsp;
        <HelpEmblavementsComponent></HelpEmblavementsComponent>
        &nbsp;
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
        &nbsp;
        <ExportButtonGroup
          @imprimer="() => {}"
          @exportExcel="() => {}"
          @exportCsv="() => {}"
        ></ExportButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
        @keydown.native.esc="load"
        @keydown.native.enter="onEnter"
      >
        <template #mobile-card="{ item }">
          <EmblavementCard
            :item="item"
            @etatSemisChanged="
              () => {
                item.etatSemis = !item.etatSemis;
                modifierEtatParcelle(item);
              }
            "
            @view="openEmblavementEditor('VISUALISATION', item)"
            @edit="openEmblavementEditor('EDITION', item)"
            @dupliquer="
              openEmblavementEditor('DUPLICATION', null, item.emblavements)
            "
            @semer="semisEnMasse(emb)"
            @retourner="retournerParcelle(item)"
          ></EmblavementCard>
        </template>
        <template #cell(libelle)="{ item }">
          <section :id="`parcelle_${item.id}`">
            {{ item.libelleParcelle }}
            <MelangeIcon v-if="item.emblavements.length > 1">
              M&eacute;lange d'esp&egrave;ces
            </MelangeIcon>
          </section>
        </template>
        <template #cell(produit)="{ item }">
          <template v-if="item.emblavements.length === 0">
            <AnomalieIcon
              v-if="item.anomalies.includes('EMBLAVEMENT_NON_SAISI')"
              :anomaliesParcelle="['EMBLAVEMENT_NON_SAISI']"
            ></AnomalieIcon>
            <b-link href="#" @click="openEmblavementEditor('EDITION', item)">
              Saisir un emblavement
            </b-link>
          </template>
          <section v-for="emb in item.emblavements" :key="emb.id">
            {{ emb.produit.libelle }}
          </section>
        </template>
        <template #cell(variete)="{ item }">
          <HistoriqueParcelleComponent
            v-if="item.emblavements.length === 0"
            :idParcelle="item.id"
          ></HistoriqueParcelleComponent>
          <section v-for="emb in item.emblavements" :key="emb.id">
            {{ emb.variete.libelle }}
            <MelangeIcon v-if="emb.semeEnMelangeVarietes">
              M&eacute;lange de vari&eacute;t&eacute;s
              <span v-if="emb.commentaireVariete">
                <br />{{ emb.commentaireVariete }}
              </span>
            </MelangeIcon>
            <ExistingCommentIcon v-else-if="emb.commentaireVariete">
              {{ emb.commentaireVariete }}
            </ExistingCommentIcon>
          </section>
        </template>
        <template #cell(surface)="{ item }">
          <span :id="`surface_${item.id}`">
            <MontantComponent
              :value="item.surfaceEmblavee - item.surfaceRetiree"
            ></MontantComponent>
          </span>
          <SurfaceCultiveeTooltip
            :target="`surface_${item.id}`"
            :surfaceEmblavee="item.surfaceEmblavee"
            :surfaceRetiree="item.surfaceRetiree"
          ></SurfaceCultiveeTooltip>
        </template>
        <template #cell(rendement)="{ item }">
          <section v-for="emb in item.emblavements" :key="emb.id">
            <CustomInputNumberComponent
              :ref="`inputRendement_${item.id}`"
              label=""
              :readOnly="
                (item.etatParcelle !== 'INTENTION' &&
                  item.etatParcelle !== 'SEMEE') ||
                editing !== item.id
              "
              :quickEdit="
                item.etatParcelle === 'INTENTION' ||
                item.etatParcelle === 'SEMEE'
              "
              :rules="`required|min_value:0|max_value:${
                emb.espece ? emb.espece.rendementMax : 1000.0
              }`"
              v-model="emb.rendementAgriculteur"
              @edit="quickEdit(item.id)"
            ></CustomInputNumberComponent>
          </section>
        </template>
        <template #cell(quantitePrevisionnelle)="{ item }">
          <section v-for="emb in item.emblavements" :key="emb.id">
            <MontantComponent
              :value="emb.quantitePrevisionnelle"
            ></MontantComponent>
          </section>
        </template>
        <template #cell(etatParcelle)="{ item }">
          <b-form-checkbox
            switch
            v-model="item.etatSemis"
            :disabled="
              item.emblavements.length <= 0 ||
              (item.etatParcelle !== 'INTENTION' &&
                item.etatParcelle !== 'SEMEE')
            "
            @change="modifierEtatParcelle(item)"
          >
            {{ item.etatParcelle !== "INTENTION" ? "Semé" : "Non semé" }}
          </b-form-checkbox>
        </template>
        <template #cell(actions)="{ item }">
          <EmblavementsActionsComponent
            v-for="(emb, index) in item.emblavements"
            :key="emb.id"
            :item="item"
            :editing="editing === item.id"
            :commentValue="emb.commentaireEmblavement"
            :noQuickEdit="index > 0"
            :noViewEdit="index > 0"
            :noDropdown="index > 0"
            @cancel="load()"
            @validate="validate(item)"
            @comment="openCommentaireEditor(item, emb)"
            @view="openEmblavementEditor('VISUALISATION', item)"
            @edit="openEmblavementEditor('EDITION', item)"
            @dupliquer="
              openEmblavementEditor('DUPLICATION', null, item.emblavements)
            "
            @semer="semisEnMasse(emb)"
            @retourner="retournerParcelle(item)"
          >
          </EmblavementsActionsComponent>
        </template>
        <template #pagination>
          Pr&eacute;visionnel :
          <strong>
            <MontantComponent
              :value="quantitePrevisionnelle"
            ></MontantComponent>
            t
          </strong>
          <span v-if="nombreMelanges">
            | M&eacute;langes d'esp&egrave;ces :
            <strong>{{ nombreMelanges }}</strong>
          </span>
        </template>
      </PaginatedTable>
      <EmblavementEditionDialog ref="emblavementEditionDialog">
      </EmblavementEditionDialog>
      <CommentaireDialog ref="commentaireEditionDialog"></CommentaireDialog>
      <SemisEnMasseDialog ref="semisEnMasseDialog"></SemisEnMasseDialog>
      <ParcelleFlipDialog ref="parcelleFlipDialog"></ParcelleFlipDialog>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import HelpEmblavementsComponent from "../../components/controls/HelpEmblavementsComponent.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ExportButtonGroup from "../../components/controls/ExportButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import EmblavementCard from "../../components/cards/EmblavementCard.vue";
import HistoriqueParcelleComponent from "../../components/controls/HistoriqueParcelleComponent.vue";
import AnomalieIcon from "../../components/controls/AnomalieIcon.vue";
import ExistingCommentIcon from "../../components/icons/ExistingCommentIcon.vue";
import MontantComponent from "../../components/MontantComponent.vue";
import SurfaceCultiveeTooltip from "../../components/controls/SurfaceCultiveeTooltip.vue";
import CustomInputNumberComponent from "../../components/inputs/CustomInputNumberComponent.vue";
import EmblavementsActionsComponent from "../../components/controls/EmblavementsActionsComponent.vue";
import SemisEnMasseDialog from "../../components/dialogs/SemisEnMasseDialog.vue";
import ParcelleFlipDialog from "../../components/dialogs/ParcelleFlipDialog.vue";
import EmblavementEditionDialog from "../../components/dialogs/EmblavementEditionDialog.vue";
import CommentaireDialog from "../../components/dialogs/CommentaireDialog.vue";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "EmblavementsView",
  components: {
    TitleBar,
    HelpEmblavementsComponent,
    TableButtonGroup,
    ExportButtonGroup,
    ErrorComponent,
    PaginatedTable,
    EmblavementCard,
    HistoriqueParcelleComponent,
    AnomalieIcon,
    ExistingCommentIcon,
    MontantComponent,
    SurfaceCultiveeTooltip,
    CustomInputNumberComponent,
    EmblavementsActionsComponent,
    SemisEnMasseDialog,
    ParcelleFlipDialog,
    EmblavementEditionDialog,
    CommentaireDialog,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.EMBLAVEMENTS),
      error: null,
      page: null,
      editing: null,
    };
  },
  computed: {
    ...tableComputed,
    semisEnMasseAutorise() {
      return this.$store.state.decl.emblavements.length > 0;
    },
    quantitePrevisionnelle() {
      return this.$store.getters["decl/quantitePrevisionnelle"];
    },
    nombreMelanges() {
      return this.$store.getters["decl/nombreMelanges"];
    },
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    load() {
      try {
        this.busy = true;
        this.editing = null;

        // Copier les données du store et les formatter
        let store = this.$store;
        let parcelles = UtilsService.deepCopy(store.state.decl.parcelles).map(
          (p) => {
            p.ilot = store.getters["decl/ilot"](p.idIlot);
            p.libelleParcelle = `${p.ilot.libelle} - ${p.libelle}`;

            // Récupérer les emblavements courants
            p.emblavements = UtilsService.deepCopy(
              store.getters["decl/emblavementsFromParcelle"](p.id)
            )
              .map((e) => {
                e.produit = store.getters["ref/produitCereale"](e.codeProduit);
                e.variete = store.getters["ref/variete"](e.codeVariete);
                e.espece = store.getters["ref/espece"](e.produit?.codeEspece);
                e.quantitePrevisionnelle =
                  (p.surfaceEmblavee - p.surfaceRetiree) *
                  e.rendementAgriculteur;
                  
                // Récupérer les infos de débouché
                if (e.produit) {
                  e.produit.debouche = this.$store.getters["ref/debouche"](e.produit.codeDebouche);
                }
                
                return e;
              })
              .sort(UtilsService.sortByStringProperty("espece.libelle"));

            // Préparer le modèle pour la coche "Semée"
            p.etatSemis = p.etatParcelle !== "INTENTION";

            // Récupérer la culture associée
            p.culture = UtilsService.deepCopy(
              store.getters["decl/culture"](p.idCulture)
            );

            return p;
          }
        );

        // Filtrer la liste
        parcelles = parcelles.filter(this.getFilterFunction());

        // Trier et paginer le résultat
        this.page = UtilsService.readOnlyPage(
          parcelles,
          this.pageable.currentPage - 1,
          this.pageable.perPage,
          this.getSortFunction()
        );
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    async quickEdit(idEmblavement) {
      this.editing = idEmblavement;
      if (!idEmblavement) {
        return;
      }
      await this.$nextTick();
      this.$refs[`inputRendement_${idEmblavement}`][0].focus();
    },
    async onEnter() {
      let target = this.page.content.find((t) => t.id === this.editing);
      if (target) {
        this.validate(target);
      }
    },
    async openCommentaireEditor(parcelle, emblavement) {
      let resultat = await this.$refs.commentaireEditionDialog.show({
        labelItems: "Emblavement",
        items: [
          `Parcelle : ${parcelle.ilot.libelle} - ${parcelle.libelle}`,
          `Culture : ${emblavement.produit.libelle}`,
        ],
        description:
          "Utilisez cet espace pour indiquer tout évènement relatif à l'emblavement lui-même (attente de semences, problème en végétation, maladie, etc.).",
        commentaire: emblavement.commentaireEmblavement,
      });
      if (resultat != null) {
        emblavement.commentaireEmblavement = resultat;
        this.emblavementsChanged(parcelle);
      }
    },
    async openEmblavementEditor(mode, parcelle, emblavements) {
      try {
        let resultat = await this.$refs.emblavementEditionDialog.show({
          mode,
          parcelle,
          emblavements,
        });
        // Création/modification abandonnée
        if (!resultat) {
          return;
        }
        // Mise à jour séquentielle pour ne pas provoquer d'anomalie dans le store
        await this.$store.dispatch(
          "decl/deleteEmblavements",
          resultat.emblavementsASupprimer
        );
        await this.$store.dispatch(
          "decl/createUpdateEmblavements",
          resultat.emblavements
        );
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
      this.load();
    },
    async validate(parcelle) {
      for (const ref of this.$refs[`inputRendement_${parcelle.id}`]) {
        await ref.validate();
      }
      this.emblavementsChanged(parcelle);
    },
    async emblavementsChanged(parcelle) {
      await this.$store.dispatch(
        "decl/createUpdateEmblavements",
        parcelle.emblavements
      );
      this.load();
    },
    modifierEtatParcelle(parcelle) {
      if (parcelle.etatSemis === true || parcelle.etatSemis === "true") {
        this.$store
          .dispatch("decl/semerParcelle", parcelle.id)
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      } else {
        this.$store
          .dispatch("decl/annulerSemisParcelle", parcelle.id)
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
    async semisEnMasse(emblavement) {
      let result = await this.$refs.semisEnMasseDialog.show({
        emblavement,
      });
      if (result) {
        let codeRecolte = this.$route.params.codeRecolte;
        let codeTiers = this.$store.getters["expl/adherentCourant"]?.codeTiers;
        this.$store
          .dispatch("decl/semisEnMasse", { ...result, codeRecolte, codeTiers })
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
    async retournerParcelle(parcelle) {
      let result = await this.$refs.parcelleFlipDialog.show({
        parcelle,
      });
      if (result) {
        this.$store
          .dispatch("decl/retournerParcelle", result)
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
  },
};
</script>
